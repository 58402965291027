/*!
* jQuery Responsive Menu - A Responsive Navbar Script

* @author  : Adam Alexandersson
* @version : 1.0.0
*
*/

(function($) {
  'use strict';

    $.fn.responsiveMenu = function(options) {

        var self = {
            target: this,
            navigation: options.nav_class,
            menu: options.menu_class,
            menu_item_link_class: options.menu_item_link_class,
            dropdown_item_label: options.dropdown_item_label,
            dropdown_item_classes: options.dropdown_item_classes,
            dropdown_item_link_classes: options.dropdown_item_link_classes,
            dropdown_item_temp_class: options.dropdown_item_temp_class,
            dropdown_item_specific_class: options.dropdown_item_specific_class,
            dropdown_item_link_class: options.dropdown_item_link_class,
            dropdown_parent: options.dropdown_item_parent_class,
            dropdown_menu: options.dropdown_menu,
            dropdown_submenu: options.dropdown_submenu,

            _init: function(){
                self._loop();
                self._resizeEvent();
            },

            // Run loop on resize event
            _resizeEvent: function() {
                $(window).resize(function () {
                    self._loop();
                });
            },

            // Returns the remaining width for the menu items to fit in.
            _remainingWidth: function() {
                var main_nav_width = self.target.width();

                if(!self.target.find('.'+ self.dropdown_item_temp_class)[0]){
                    return (main_nav_width - 1);
                }else{
                    var width = (self.target.find('.'+ self.dropdown_item_temp_class).width()) + 4;
                    return (main_nav_width - width);
                }
            },

            // Returns the sum of the menu items width.
            _itemsTotalWidth: function (){
                var list_items_width = 0;
                self.target.find('.'+self.menu+ ' > li').not('.'+ self.dropdown_item_temp_class).each(function() {
                    list_items_width += $(this).width();
                });
                return list_items_width;
            },

            // Loop and determine whether the items fit or not
            _loop: function() {
                var loop = true, count = 0;
                if(self._remainingWidth() > 0) {
                    while (loop === true){
                        if( self._remainingWidth() <= self._itemsTotalWidth() ){
                            if(!self.target.find('.'+ self.dropdown_item_temp_class)[0]){
                                self.target.find('.'+self.menu).append('<li class="'+ self.dropdown_item_classes +'"><a class="'+ self.dropdown_item_link_classes +'" title="'+ self.dropdown_item_label +'" href="#" data-toggle="dropdown" aria-expanded="false">'+ self.dropdown_item_label +'</a><ul role="menu" class="'+ self.dropdown_menu +'"></ul></li>');
                            }

                            self.target.find('.'+self.menu+' .'+self.dropdown_item_temp_class).show();
                            var list_item = self.target.find('.'+self.menu+' > li:nth-last-child(2)').detach();
                            // var list_item_link = list_item.find('.nav-link').removeClass(self.menu_item_link_class).addClass(self.dropdown_item_link_class);
                            self.target.find('.'+self.menu+' .'+self.dropdown_item_temp_class+ '> .'+self.dropdown_menu).prepend(list_item);

                            if(self.target.find('.'+self.menu+' .'+self.dropdown_item_temp_class+ ' .'+self.dropdown_menu+' li:first-child').hasClass(self.dropdown_parent)){
                                self.target.find('.'+self.menu+' .'+self.dropdown_item_temp_class+ ' .'+self.dropdown_menu+' li:first-child').removeClass(self.dropdown_item_specific_class).addClass(self.dropdown_submenu);
                            }
                        }else{
                            var sub_list_item = self.target.find('.'+self.menu+' .'+self.dropdown_item_temp_class+ ' > .'+self.dropdown_menu+' > li:first-child').detach();
                            sub_list_item.insertBefore('.'+self.navigation+' .'+self.menu+' .'+self.dropdown_item_temp_class);

                            if(self.target.find('.'+self.menu+' > li:nth-last-child(2)').hasClass(self.dropdown_parent)){
                                self.target.find('.'+self.menu+' > li:nth-last-child(2)').removeClass(self.dropdown_submenu).addClass(self.dropdown_item_specific_class);
                            }

                            if(self.target.find('.'+self.menu+' .'+self.dropdown_item_temp_class+ ' > .'+self.dropdown_menu).is(':empty')){
                                self.target.find('.'+self.menu+' .'+self.dropdown_item_temp_class).remove();
                            }
                        }

                        if( self._remainingWidth() <= self._itemsTotalWidth() ){
                            loop = true;
                        }else{
                            loop = false;
                        }

                        // A max loop count on 30 for safety's sake
                        if(count > 30){loop = false;} count++;
                    }
                }
            },
        };

        self._init();
        return this;
    };

})(jQuery);