(function($) {

    $(document).ready(function() {
        $('.image-slider').slick({
            asNavFor: '.image-slider-nav',
            slidesToShow: 1,
            slidesToScroll: 1,
            touchMove: true,
            arrows: false,
            fade: true,
            infinite: true,
            dots: false,
            speed: 300,
            waitForAnimate: true,
            focusOnSelect: true,
        });

        $('.image-slider-nav').slick({
            asNavFor: '.image-slider',
            slidesToShow: 4,
            slidesToScroll: 1,
            centerMode: false,
            touchMove: true,
            speed: 400,
            infinite: true,
            dots: false,
            arrows: false,
            waitForAnimate: true,
            focusOnSelect: true,
        });

        $('.product-carousel').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            centerMode: false,
            touchMove: true,
            speed: 400,
            infinite: true,
            dots: false,
            arrows: true,
            waitForAnimate: true,
            focusOnSelect: true,
            prevArrow:"<button type='button' class='slick-prev rounded-circle shadow'><i class='icon ion-android-arrow-back' aria-hidden='true'></i></button>",
            nextArrow:"<button type='button' class='slick-next rounded-circle shadow'><i class='ico ion-android-arrow-forward' aria-hidden='true'></i></button>",

            responsive: [
                {
                  breakpoint: 1400,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    centerMode: false,
                    touchMove: true,
                    speed: 400,
                    infinite: true,
                    dots: false,
                    arrows: false,
                    waitForAnimate: true,
                    focusOnSelect: true
                  }
                },
                {
                  breakpoint: 991,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerMode: false,
                    touchMove: true,
                    speed: 400,
                    infinite: true,
                    dots: false,
                    arrows: false,
                    waitForAnimate: true,
                    focusOnSelect: true
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                    touchMove: true,
                    speed: 400,
                    infinite: true,
                    dots: false,
                    arrows: false,
                    waitForAnimate: true,
                    focusOnSelect: true
                  }
                },
                {
                  breakpoint: 567,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    touchMove: true,
                    speed: 400,
                    infinite: true,
                    dots: false,
                    arrows: false,
                    waitForAnimate: true,
                    focusOnSelect: true
                  }
                }
            ]
        });
    });

})(jQuery); 