/*!
* jQuery Sticky Menu - A Sticky Menu Script

* @author  : Adam Alexandersson
* @version : 1.0.0
*
*/

(function($) {
  'use strict';

    $.fn.stickyMenu = function(options) {

        var self = {
            target: this,
            offsetTarget: options.offsetTarget,
            class: options.class,
            parent: this.parent(),

            _init: function(){
                self._determiningOffset();
                self._determiningState();
                self._scrollEvent();
                self._resizeEvent();
            },

            _resizeEvent: function() {
                $(window).resize(function () {
                    self._determiningOffset();
                });
            },

            _scrollEvent: function() {
                $(window).scroll(function () {
                    self._determiningState();
                });
            },

            _determiningState: function() {
                if (self.target.length > 0){
                    var heroOffset = self.parent.offset().top - $(window).scrollTop();
                    if (heroOffset <= self.offset) {
                      self.target.addClass(self.class);
                      self.parent.css('padding-top', self.target.outerHeight());
                      self.target.css('top', self.offset);
                    } else {
                      self.target.removeClass(self.class);
                      self.parent.css('padding-top', 0);
                      self.target.css('top', 0);
                    }
                }
            },

            _determiningOffset: function() {
                self.offset = self.offsetTarget.outerHeight();
            }
        };

        self._init();
        return this;
    };

})(jQuery);