/*
* jQuery Overflow Menu - A Overflow Menu Script

* @author  : Adam Alexandersson
* @version : 1.0.0
*
*/

(function($) {
  'use strict';

    $.fn.overflowMenu = function(options) {

        var self = {
            target: this,
            menu: this.find('ul'),
            class: options.class,
            leftClass: options.menuLeftClass,
            rightClass: options.menuRightClass,
            arrowOffset: options.arrowOffset,
            edgeOffset: options.edgeOffset,

            _init: function(){
                if(self.target.length > 0) {
                    if(self._determiningState()) {
                        self._toggleEffect();
                        self._targetScroll();
                    }
                    self._resizeWindowEvent();
                }
            },

            _itemsTotalWidth: function() {
                var list_items_width = 0;
                self.target.find("> ul > li").each(function() {
                    list_items_width += $(this).width();
                });
                return list_items_width;
            },

            _targetScroll: function() {
                self.menu.scroll(function () {
                    self._toggleEffect();
                });
            },

            _toggleEffect: function() {
                var newScrollLeft = self.menu.scrollLeft(),
                    scrollWidth = self.menu.get(0).scrollWidth;

                if (scrollWidth - newScrollLeft - self.menu.width() <= self.arrowOffset) {
                    self.target.addClass(self.rightClass);
                } else {
                    self.target.removeClass(self.rightClass);
                }
                if (newScrollLeft <= self.arrowOffset) {
                    self.target.addClass(self.leftClass);
                } else {
                    self.target.removeClass(self.leftClass);
                }
            },

            _determiningState: function() {
                if(self._itemsTotalWidth() + (self.edgeOffset * 2) >= self.target.width()){
                    self.target.addClass(self.class);
                    return true;
                }else{
                    self.target.removeClass(self.class);
                    return false;
                }
            },

            _resizeWindowEvent: function() {
                $(window).resize(function () {
                    self._determiningState();
                    self._toggleEffect();
                });
            },
        };

        self._init();
        return this;
    };

})(jQuery);