var ecommerce = {
	addToCart: function(name, sku, price, category, quantity){
		dataLayer.push({
			'event': 'addToCart',
			'ecommerce': {
				'currencyCode': 'SEK',
				'add': {
					'products': [{
						'name': name,
						'id': sku,
						'price': price,
						//'brand': 'Google',
						'category': category,
						//'variant': 'Gray',
						'quantity': quantity
					}]
				}
			}
		});
	},
	removeFromCart: function(name, sku, price, category, quantity){
		dataLayer.push({
			'event': 'removeFromCart',
			'ecommerce': {
				'remove': {
					'products': [{
						'name': name,
						'id': sku,
						'price': price,
						//'brand': 'Google',
						'category': category,
						//'variant': 'Gray',
						'quantity': quantity
					}]
				}
			}
		});
	},
	checkout: function () {
		window.dataLayer = window.dataLayer || [];
		var transactionProducts = [];
		$('.transactionProduct').each(function(index){
			transactionProducts.push({
				'sku': $('#transactionProduct' + index + '_Sku').val(),
				'name': $('#transactionProduct' + index + '_Name').val(),
				'category': $('#transactionProduct' + index + '_Category').val(),
				'price': $('#transactionProduct' + index + '_Price').val(),
				'quantity': $('#transactionProduct' + index + '_Quantity').val()
			});
		});
		dataLayer.push({
			'event': 'checkout',
			'ecommerce': {
			  'checkout': {
				'actionField': {'step': 1},
				'products': transactionProducts
			 }
		   }
		});
	},
	checkoutStep: function (step, option) {
		window.dataLayer = window.dataLayer || [];
		dataLayer.push({
			'event': 'checkoutOption',
			'ecommerce': {
			  'checkout_option': {
				'actionField': { 'step': step, 'option': option }
			  }
			}
		});
	},
	orderConfirmation: function () {
		window.dataLayer = window.dataLayer || [];
		var transactionProducts = [];
		$('.transactionProduct').each(function(index){
			transactionProducts.push({
				'sku': $('#transactionProduct' + index + '_Sku').val(),
				'name': $('#transactionProduct' + index + '_Name').val(),
				'category': $('#transactionProduct' + index + '_Category').val(),
				'price': $('#transactionProduct' + index + '_Price').val(),
				'quantity': $('#transactionProduct' + index + '_Quantity').val()
			});
		});
		dataLayer.push({
			'event': 'purchase',
			'ecommerce': {
				'purchase': {
					'actionField': {
						'id': $('#transactionId').val(),
						'affiliation': $('#transactionAffiliation').val(),
						'revenue': $('#transactionTotal').val(),
						//'tax': $('#transactionTax').val(), //Optional + we only have prices incl VAT atm
						'shipping': $('#transactionShipping').val()
					},
					'products': transactionProducts
				}
			}
		});
	},
};